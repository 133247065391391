import {
    documents,
    time,
    person,
    clipboard,
    car,
    copy,
    ticket,
    wallet,
    card,
    people,
    book,
    informationCircle,
    documentText,
    listCircle,
    hourglass,
    newspaper,
    calendar,
    cube,
    home,
    list,
    barcode,
    shareSocial,
} from "ionicons/icons";

//Chiave localsotrage
const LOCALSTORAGE_KEY = `user${process.env.VUE_APP_LOCALSTORAGE_KEY}`;

// Pagina da vedere dopo il login
const pageAfterLogin = "presenze";

// Pulsanti presenti nella dashboard
const dashbordButtons = {
    presenze: true,
    documenti: true,
    richieste: true,
    automezzi: true,
    spese: true,
    tickets: true,
};

// Configurazione del menu
const config_menu = [
    {
        show: true,
        route: "presenze",
        icon: time,
        name: "Presenze",
        allowedRoles: ["Admin", "Employee", "Venditori", "Manager Venditori"],
    },
    {
        show: true,
        route: "documenti",
        icon: documents,
        name: "Documenti",
        allowedRoles: ["Admin", "Employee", "Venditori", "Manager Venditori"],
    },
    {
        show: true,
        route: "richieste",
        icon: clipboard,
        name: "Richieste",
        allowedRoles: ["Admin", "Employee", "Venditori", "Manager Venditori"],
    },
    {
        show: false,
        route: "banca",
        icon: hourglass,
        name: "Banca ore",
        allowedRoles: ["Admin", "Employee"],
    },
    {
        show: false,
        route: "automezzi",
        icon: car,
        name: "Automezzi",
        allowedRoles: ["Admin"],
    },
    {
        show: true,
        route: "spese",
        icon: wallet,
        name: "Note spesa",
        allowedRoles: ["Admin", "Employee", "Venditori", "Manager Venditori"],
    },
    {
        show: true,
        route: "calendario",
        icon: calendar,
        name: "Calendario",
        allowedRoles: ["Admin", "Employee", "Venditori", "Manager Venditori"],
    },
    {
        show: false,
        route: "rimborsi",
        icon: card,
        name: "Rimborsi km",
        allowedRoles: ["Admin", "Employee"],
    },
    {
        show: false,
        route: "clienti",
        icon: people,
        name: "Anagrafiche",
        allowedRoles: ["Admin"],
    },
    {
        show: false,
        route: "progetti",
        icon: listCircle,
        name: "Progetti",
        allowedRoles: ["Admin"],
    },
    {
        show: true,
        route: "leads",
        icon: book,
        name: "Leads",
        allowedRoles: ["Admin", "Venditori", "Manager Venditori"],
    },
    {
        show: false,
        route: "interventi",
        icon: documentText,
        name: "Interventi",
        allowedRoles: ["Admin"],
    },
    {
        show: true,
        route: "rapportini",
        icon: documentText,
        name: "Rapportini",
        allowedRoles: ["Admin", "Employee", "Venditori", "Manager Venditori"],
    },
    {
        show: true,
        route: "tickets",
        icon: ticket,
        name: "Tickets",
        allowedRoles: ["Admin", "Employee", "Venditori", "Manager Venditori"],
    },
    {
        show: false,
        route: "task",
        icon: home,
        name: "Tasks",
        allowedRoles: ["Admin"],
    },
    {
        show: false,
        route: "timesheet",
        icon: time,
        name: "Timesheet",
        allowedRoles: ["Admin"],
    },
    {
        show: false,
        route: "bacheca",
        icon: shareSocial,
        name: "Bacheca",
        allowedRoles: ["Admin"],
    },
    {
        show: true,
        route: "programma",
        icon: calendar,
        name: "Programma lavoro",
        allowedRoles: ["Admin", "Employee", "Venditori", "Manager Venditori"],
    },
    {
        show: false,
        route: "ordini",
        icon: cube,
        name: "Ordini interni",
        allowedRoles: ["Admin"],
    },
    {
        show: false,
        route: "sondaggi",
        icon: newspaper,
        name: "Sondaggi",
        allowedRoles: ["Admin"],
    },
    {
        show: true,
        route: "magazzino",
        icon: barcode,
        name: "Magazzino",
        allowedRoles: ["Admin", "Employee", "Venditori", "Manager Venditori"],
    },
    {
        show: false,
        route: "todo",
        icon: list,
        name: "Liste",
        allowedRoles: ["Admin", "Employee"],
    },
    /* {
        show: true,
        route: "testbarcode",
        icon: list,
        name: "TestBarcode",
        allowedRoles: ['Admin', 'Employee']
    }, */
    {
        show: true,
        route: "profilo",
        icon: person,
        name: "Profilo",
        allowedRoles: ["Admin", "Employee", "Venditori", "Manager Venditori"],
    },
];

/**
 *
 * RAPPORTINI
 *
 */
// Configurazione operatori e prodotti
const showOperatori = true;
const showProdotti = true;

// Configurazione dei permessi per ruolo
const rolePermissions = {
    Admin: ["*"], // '*' indica accesso a tutte le rotte
    Employee: ["login", "presenze", "documenti", "richieste", "banca ore", "automezzi", "Nota Spese", "tickets", "magazzino", "rapportini", "Programma lavoro", "profilo", "changePassword"],
    "Venditori": ["login", "presenze", "documenti", "richieste", "automezzi", "spese", "calendario", "clienti", "progetti", "leads", "Programma lavoro", "profilo", "changePassword"],
    "Manager Venditori": ["login", "presenze", "documenti", "richieste", "automezzi", "spese", "calendario", "clienti", "progetti", "leads", "Programma lavoro", "profilo", "changePassword"],
};

export { LOCALSTORAGE_KEY, pageAfterLogin, dashbordButtons, config_menu, showProdotti, showOperatori, rolePermissions };
